import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PostBlock from '../components/post-block'

import { RefTagger } from 'react-reftagger';

const PostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <RefTagger bibleVersion={'ESV'} />

      <div className="mt-4"></div>
      
      <PostBlock post={post} site={data.site}/>
      
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/post/${previous.frontmatter.postId}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/post/${next.frontmatter.postId}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        audioServerUrl
        resourceServerUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      excerpt
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        lessonDate(formatString: "MMMM DD, YYYY")
        title
        description
        postType
        postId
        audio
        video
        passages
        resources
        series {
          frontmatter {
            name
            seriesId
            coverImage {
              childImageSharp {
                gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        }
        subSeries {
          frontmatter {
            name
            seriesId
            coverImage {
              childImageSharp {
                gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        }
        venue {
          frontmatter {
            name
            venueId
          }
        }
        presenters {
          frontmatter {
            name
            presenterId
          }
        }
        topics {
          frontmatter {
            name
            type
            topicId
          }
        }
        relatedPosts {
          frontmatter {
            postId
            date(formatString: "MMMM DD, YYYY")
            lessonDate(formatString: "MMMM DD, YYYY")
            title
            description
            postType
            audio
            video
            passages
            series {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            subSeries {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            venue {
              frontmatter {
                name
                venueId
              }
            }
            presenters {
              frontmatter {
                name
                presenterId
              }
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        postId
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        postId
      }
    }
  }
`
